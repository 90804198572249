import { useState, useEffect } from "react";
import { apiSite } from "../config/urlLink";
import { GetToken } from "./apiAuth";

const usePost = (url, filter, path = "course_schedule") => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { headers } = GetToken();

  useEffect(() => {
    const abortCtr = new AbortController();
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiSite}api/method/smi.${path}.${url}`, {
          signal: abortCtr.signal,
          method: "POST",
          headers: headers,
          credentials: "include",
          body: JSON.stringify(filter),
        })
        if (!response.ok) {
          throw new Error("Could not fetch the data for that resource");
        }
        const result = await response.json();

        setData(result);
        setIsLoading(false);
        setError(null);
        // console.log(data);

      } catch (err) {

        if (err.name === "AbortError") {
          console.log("fetch data aborted");
        } else {
          setIsLoading(false);
          setError(err.message);
          // console.log(err.message);
        }
      }
    }
    setTimeout(() => {
      fetchData()
    }, 3000);
    return () => abortCtr.abort();
    // eslint-disable-next-line
  }, [filter]);

  return { data, isLoading, error };
};

export default usePost;
