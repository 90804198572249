import React, { useMemo, useState } from "react";
import {
  ShortDate,
  ShortDateNumber,
  ShortDateFull,
  LongDate,
  CalculateAge,
  CompDate,
} from "../custom/dateFormatter";
import useWindow from "../custom/useWindow";

const DashStaffReportDetail = (props) => {
  const { width } = useWindow();
  const [search, setSearch] = useState({ src: "", status: true, sort: "name" });
  const [attDetail, setAttDetail] = useState({ id: "", data: "" });
  const handleSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };
  const handleAttDet = (e, c, i) => {
    e.preventDefault();
    setAttDetail({ [c.student]: c });
  };
  const attFil = useMemo(() => {
    const searchRegex = search.src && new RegExp(`${search.src}`, "gi");
    const searchRegex2 =
      search.student && new RegExp(`${search.student}`, "gi");
    return (
      props.data &&
      Object.values(props.data).flatMap((arr) =>
        arr.filter(
          (f) =>
            // (search.status ? f.status === "Present" : f.status === "Absent") && // filter by status if it's defined
            (search.start ? search.start < f.schedule_date : f.schedule_date) && // filter by start date if it's defined
            (search.end ? search.end > f.schedule_date : f.schedule_date) && // filter by end date if it's defined
            (!searchRegex ||
              searchRegex.test(f.comment + f.lesson + f.video_url + f.owner)) &&
            (!searchRegex2 || searchRegex2.test(f.student_name + f.student))
        )
      )
    );
  }, [props.data, search.src, search.start, search.end, search.student]);
  return (
    attFil && (
      <div onMouseLeave={() => setAttDetail({})}>
        <div
          style={{
            display: "flex",
            flexDirection: width > 450 ? "row" : "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 0 10px 0",
          }}
        >
          <div className="w-100" style={{ display: "flex" }}>
            <p
              style={{
                margin: "0",
                padding: "0",
              }}
            >
              Attendance
            </p>
          </div>
          <div
            className="w-100"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <input
              className="form-control"
              style={{
                padding: "3px 10px",
                fontSize: "12px",
              }}
              type="text"
              placeholder="Search student here"
              name="student"
              value={search.student ? search.student : ""}
              onChange={handleSearch}
            />
            <input
              className="form-control"
              style={{
                padding: "3px 10px",
                fontSize: "12px",
              }}
              type="text"
              placeholder="Search Attendance here"
              name="src"
              value={search.src ? search.src : ""}
              onChange={handleSearch}
            />
          </div>
          <div
            className="w-100"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <input
              className="form-control"
              style={{
                padding: "3px 10px",
                fontSize: "12px",
              }}
              type="date"
              name="start"
              value={search.start}
              onChange={(e) => setSearch({ ...search, start: e.target.value })}
            />
            <input
              className="form-control"
              style={{
                padding: "3px 10px",
                fontSize: "12px",
              }}
              type="date"
              name="end"
              value={search.end}
              onChange={(e) => setSearch({ ...search, end: e.target.value })}
            />
            <button
              className="btn btn-warning"
              style={{
                padding: "3px 10px",
                fontSize: "12px",
              }}
              onClick={() => setSearch({ status: true, start: "", end: "" })}
            >
              CLEAR
            </button>
          </div>
          {/* <div
            style={{
              display: "flex",
              margin: "0",
              padding: "0",
              borderRadius: "5px",
              background: "#e9e9e9",
              width: "fit-content",
            }}
          >
            <div
              className="form-check form-switch"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                margin: "0",
                padding: "0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "0 10px",
                }}
              >
                <label
                  className="form-check-label"
                  htmlFor="statusSwitch"
                  style={{
                    display: "flex",
                    margin: "0",
                    padding: "0",
                    color: "grey",
                    fontSize: "10px",
                    fontWeight: "700",
                  }}
                >
                  {search.status ? "ATTENDED" : "ABSENT"}
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  paddingRight: "10px",
                }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="statusSwitch"
                  name="status"
                  defaultChecked={search.status}
                  onClick={() =>
                    setSearch({ ...search, status: !search.status })
                  }
                  style={{
                    margin: "0",
                    padding: "0",
                    backgroundColor: search.status ? "#0d6efd" : "crimson",
                    borderColor: search.status ? "#0d6efd" : "crimson",
                  }}
                />
              </div>
            </div>
          </div> */}
        </div>
        <div
          className="w-100"
          style={{
            height: "75vh",
            overflow: "auto",
            padding: "0 10px 0 0",
            marginBottom: width > 450 ? "0px" : "100px",
          }}
        >
          {Object.keys(props.data)
            .sort((a, b) => {
              const nameA = props.data[a][0].student_name.toUpperCase(); // convert to uppercase to ignore letter case
              const nameB = props.data[b][0].student_name.toUpperCase(); // convert to uppercase to ignore letter case
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .map((d) => (
              <div
                style={{
                  paddingBottom: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: width > 450 ? "row" : "column",
                    background: "#e9e9e9",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className={
                      Object.keys(attDetail)[0] === d ? "col-md-5" : "col-md-3"
                    }
                    style={{ margin: "0", padding: "10px", lineHeight: "1" }}
                  >
                    <div className="w-100">
                      <i style={{ color: "grey", fontSize: "11px" }}>{d}</i>
                      <br />
                      {props.data[d][0].student_name}
                    </div>
                    <div className="w-100">
                      {Object.keys(attDetail)[0] === d ? (
                        <>
                          <div
                            className="w-100"
                            style={{
                              padding: "15px 0 0 0",
                            }}
                          >
                            {/* {console.log(attDetail[d])} */}
                            <div
                              className="card"
                              style={{
                                padding: "15px",
                                position: "relative",
                                fontSize: "12px",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                  background: "#e9e9e9",
                                  color: "grey",
                                  padding: "5px 10px",
                                }}
                                onClick={() => setAttDetail({})}
                              >
                                X
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    background: "#e9e9e9",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {attDetail[d].schedule_date &&
                                    ShortDate(attDetail[d].schedule_date)}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "0 10px",
                                  }}
                                >
                                  <span className="__cs_hour">
                                    {attDetail[d].from_time &&
                                      attDetail[d].from_time.split(":")[0]}
                                  </span>
                                  .
                                  {attDetail[d].from_time &&
                                    attDetail[d].from_time.split(":")[1]}{" "}
                                  -
                                  <span className="__cs_hour">
                                    {attDetail[d].to_time &&
                                      attDetail[d].to_time.split(":")[0]}
                                  </span>
                                  .
                                  {attDetail[d].to_time &&
                                    attDetail[d].to_time.split(":")[1]}
                                </div>
                              </div>
                              <div style={{ padding: "10px 0" }}>
                                {attDetail[d].course_schedule &&
                                  attDetail[d].course_schedule}
                                <br />
                                {attDetail[d].student_group}
                              </div>
                              {/* {attDetail[d].course.split(" - ")[1]} */}
                              <div style={{ position: "relative" }}>
                                <span
                                  style={{
                                    color: "lightgrey",
                                    fontSize: "12px",
                                    position: "absolute",
                                    top: "-0px",
                                    left: "0px",
                                  }}
                                >
                                  {ShortDateFull(attDetail[d].creation)}
                                </span>
                                <hr />
                                <span
                                  style={{
                                    color: "lightgrey",
                                    fontSize: "12px",
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                  }}
                                >
                                  {attDetail[d].name}
                                </span>
                              </div>
                              <div style={{ position: "relative" }}>
                                <div>
                                  {attDetail[d].student} <br />
                                  {attDetail[d].student_name} <br />
                                </div>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-17px",
                                    right: "0",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {[...Array(5)].map((star, i) => {
                                      i += 1;
                                      return (
                                        <div
                                          type="star-rating-button"
                                          key={i}
                                          className={
                                            i <= attDetail[d].growth_point
                                              ? "on"
                                              : "off"
                                          }
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          <i
                                            className={
                                              i <= attDetail[d].growth_point
                                                ? "bi bi-star-fill"
                                                : "bi bi-star"
                                            }
                                          ></i>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div
                                  className="w-100"
                                  style={{ position: "relative" }}
                                >
                                  <hr />
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "0",
                                      top: "-20px",
                                      color: "grey",
                                      fontSize: "20px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {attDetail[d].status.toUpperCase()}
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "0",
                                      top: "1px",
                                      color: "lightgrey",
                                      background: "white",
                                    }}
                                  >
                                    {
                                      {
                                        0: "DRAFT",
                                        1: "SUBMITTED",
                                        2: "CANCELED",
                                      }[attDetail[d].docstatus]
                                    }
                                  </div>
                                </div>
                                <div
                                  style={{ height: "150px", overflowY: "auto" }}
                                >
                                  <small>
                                    <b>Comment : </b>
                                  </small>
                                  <p
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {attDetail[d].comment}
                                  </p>
                                  <small>
                                    <b>Lesson : </b>
                                  </small>
                                  <p
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {attDetail[d].lesson}
                                  </p>
                                  <small>
                                    <b>Video Url : </b>
                                  </small>
                                  <p
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {attDetail[d].video_url}
                                  </p>
                                </div>
                                <div
                                  className="w-100"
                                  style={{ height: "15px" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      Object.keys(attDetail)[0] === d ? "col-md-7" : "col-md-9"
                    }
                    style={{
                      margin: "0",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {attFil &&
                      attFil
                        .filter((f) => f.student === d)
                        .sort((a, b) =>
                          a.schedule_date < b.schedule_date
                            ? -1
                            : a.schedule_date > b.schedule_date
                            ? 1
                            : 0
                        )
                        .map((c, i) => (
                          <div
                            key={i + c.name}
                            className={
                              Object.keys(attDetail)[0] === d
                                ? "col-md-4 col-6"
                                : "col-md-3 col-6"
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              lineHeight: "1",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => handleAttDet(e, c, i)}
                          >
                            <div
                              style={{
                                background: {
                                  Present: "limegreen",
                                  Absent: "crimson",
                                }[c.status],
                                borderRadius: "3px",
                                padding: "5px",
                                color: "white",
                              }}
                            >
                              {c.schedule_date && ShortDate(c.schedule_date)}
                              <br />
                              {c.status + " - " + c.date}
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  );
};

export default DashStaffReportDetail;
