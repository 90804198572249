import React, { useMemo, useState } from "react";
import usePost from "../custom/usePost";
import "../assets/css/dashRep.css";
import DashStaffReportDetail from "./dashStaffReportDetail";
import useWindow from "../custom/useWindow";

const DashStaffReport = () => {
  const { width } = useWindow();
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [search, setSearch] = useState({ src: "", status: true });
  const data = {
    manager_email: sessionStorage.getItem("user_id"),
    company: JSON.parse(sessionStorage.getItem("emp_id")).company,
  };

  const { data: teacher } = usePost("get_teacher_list", data);
  const { data: attendance, isLoading } = usePost(
    "get_teacher_student_attendance_list",
    data
  );
  let tea = teacher && teacher.message;
  let att = attendance && attendance.message;
  const handleSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
    console.log([e.target.name], e.target.value);
  };
  const teaFil = useMemo(() => {
    const searchRegex = search.src && new RegExp(`${search.src}`, "gi");
    return (
      tea &&
      tea.filter(
        (d) =>
          (!searchRegex || searchRegex.test(d.istructor_name + d.name)) &&
          d.status === (search.status ? "Active" : "Left")
      )
    );
  }, [tea, search.src, search.status]);

  // const attFil = useMemo(() => {
  //   return att && Object.keys(att).filter((f) => f === selectedTeacher);
  // }, [att, selectedTeacher]);
  return (
    <>
      <div className="w-100" style={{ height: "85vh", overflow: "auto" }}>
        {/* {console.log(att)} */}
        <div
          className="row col-md-12"
          style={{ padding: "0 15px", margin: "0" }}
        >
          <div
            className="col-md-3"
            style={{
              padding: "0",
              margin: "0",
            }}
          >
            <div className="w-100" style={{}}>
              <div style={{ padding: "0 0 10px 0" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    lineHeight: "1",
                    padding: "0 0 10px 0",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      Instructor
                    </p>
                  </div>
                  <div
                    style={{
                      margin: "0",
                      padding: "0",
                      borderRadius: "5px",
                      background: "#e9e9e9",
                      width: "fit-content",
                    }}
                  >
                    <div
                      className="form-check form-switch"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          padding: "0 10px",
                        }}
                      >
                        <label
                          className="form-check-label"
                          htmlFor="statusSwitch"
                          style={{
                            display: "flex",
                            margin: "0",
                            padding: "0",
                            color: "grey",
                            fontSize: "10px",
                            fontWeight: "700",
                          }}
                        >
                          {search.status ? "ACTIVE" : "LEFT"}
                        </label>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          paddingRight: "10px",
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="statusSwitch"
                          name="status"
                          defaultChecked={search.status}
                          onClick={() =>
                            setSearch({ ...search, status: !search.status })
                          }
                          style={{
                            margin: "0",
                            padding: "0",
                            backgroundColor: search.status ? "" : "crimson",
                            borderColor: search.status ? "" : "crimson",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-auto"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    className="form-control"
                    style={{
                      padding: "5px 15px",
                    }}
                    type="text"
                    placeholder="Search instructor here"
                    name="src"
                    onChange={handleSearch}
                  />
                </div>
              </div>
              {/* Teacher List */}
              <div
                className="w-100"
                style={{
                  height: "72vh",
                  overflow: "auto",
                  padding: "0 10px 0 0",
                }}
              >
                {/* {console.log(attFil)} */}
                {teaFil &&
                  teaFil
                    .sort((a, b) =>
                      a.instructor_name < b.instructor_name
                        ? -1
                        : a.instructor_name > b.instructor_name
                        ? 1
                        : 0
                    )
                    .map((d) => (
                      <div className="w-100">
                        <div
                          className={
                            selectedTeacher === d.name
                              ? "__teacherlistitem __teacherlistitemactive"
                              : "__teacherlistitem "
                          }
                          onClick={() => setSelectedTeacher(d.name)}
                        >
                          <div className="__itemarrow">
                            <i className="bi bi-arrow-right-square"></i>
                          </div>
                          {isLoading ? (
                            <div className="blink-1">
                              <div className="__itembadgecontainer">
                                <div
                                  className="__itembadge"
                                  style={{ background: "#e9e9e9" }}
                                >
                                  <i className="bi bi-person-fill"></i>
                                </div>
                                <div
                                  className="__itembadge"
                                  style={{ background: "#e9e9e9" }}
                                >
                                  <i className="bi bi-file-person-fill"></i>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="__itembadgecontainer">
                              <div
                                className="__itembadge"
                                style={{ background: "orange" }}
                              >
                                {att && att[d.name] !== undefined
                                  ? Object.keys(att[d.name]).length
                                  : [].length}
                                <i className="bi bi-person-fill"></i>
                              </div>
                              <div
                                className="__itembadge"
                                style={{ background: "royalblue" }}
                              >
                                {att && att[d.name] !== undefined
                                  ? Object.values(att[d.name]).reduce(
                                      (acc, arr) => acc + arr.length,
                                      0
                                    )
                                  : [].length}
                                <i className="bi bi-file-person-fill"></i>
                              </div>
                            </div>
                          )}
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              fontSize: "8px",
                              fontWeight: "700",
                              color: "white",
                              background: {
                                Active: "limegreen",
                                Left: "crimson",
                              }[d.status],
                              padding: "5px",
                              width: "60px",
                              textAlign: "center",
                              borderRadius: "0 5px 0 7px",
                            }}
                          >
                            {d.status.toUpperCase()}
                          </div>
                          <i style={{ color: "grey", fontSize: "10px" }}>
                            {d.name}
                          </i>
                          <br /> {d.instructor_name}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <div
            className="col-md-9"
            style={{
              padding: width > 450 ? "0 15px" : "0",
              margin: "0",
            }}
          >
            <div className="w-100" style={{}}>
              {att && <DashStaffReportDetail data={att[selectedTeacher]} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashStaffReport;
